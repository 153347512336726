import responseService from "../../Services/responseService";
import config from "../../config";

class ReviewState{
    async addReview(text,count){
        try{
            const result = await responseService.responseApiServer(config.URL_REVIEW_ADD, {text, count})
            return result
        }catch (e) {
            return {warning:true, message:'Ошибка сервера'}
        }
    }
    async removeProgress(){
        try{
            const result = await responseService.responseApiServer(config.URL_REMOVE_PROGRESS, {})
            return result
        }catch (e) {
            return {warning:true, message:'Ошибка сервера'}
        }
    }
}

export default new ReviewState()