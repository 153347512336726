import React, {useState} from 'react';
import {geolocated} from "react-geolocated";
import s from './QuestionCardDesctop.module.css'
import {Link} from 'react-router-dom'
import HistoryPage from "../HistoryPage/HistoryPage";
import ResultModal from "../ResultModal/ResultModal";
import Maps from "./Maps/Maps";
import ReviewState from "../../../State/ReviewState/ReviewState";

const QuestionCardDesctop = (props) => {
    const [isHistoryView, setIsHistoryView] = useState(false)
    const [isViewAnswer, setIsViewAnswer] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [isViewMap, setIsViewMap] = useState(false)
    const [geolocation, setGeolocation] = useState([])
    //
    const closeModal = ()=>{
        setIsViewAnswer(false)
        setIsViewMap(false)
    }


    const checkAnswer = (coordinate)=>{

        setGeolocation(coordinate)
    }

    return (
        <>
            {!isHistoryView?
                <>
                    <div className={s.card_wrapper}>
                        <div className={s.content}>
                            <div className={s.title}>Вопрос №{props.currentTaskNumber+1}</div>
                            <div className={s.text}>{props.task.text}</div>
                            <Maps geo={geolocation} changeTask={checkAnswer} close={closeModal}/>
                        </div>

                        <div className={s.btn_wrapper}>
                            <div className={'btn_red'}>Получить подсказку</div>
                            <div onClick={async ()=>{
                                const result = await props.checkAnswer(geolocation[0],geolocation[1])
                                if(!result.warning){
                                    if(result.answer){
                                        props.setProgress(result.progress)
                                        setIsHistoryView(true)
                                        setAnswer(true)
                                        setIsViewAnswer(true)
                                    }else{
                                        setAnswer(false)
                                        setIsViewAnswer(true)}
                                    setTimeout(()=>{setIsViewAnswer(false)}, 1200)
                                }
                            }

                            } className={'btn_blue'}>Ответить</div>

                            <div className={'btn_green'}>Справка</div>
                            {/*{props.task.img? <img src={props.task.img} alt=""/>:<></>}*/}

                        </div>
                        <div className={s.btn_wrapper}>
                            <div onClick={async (e)=>{
                                e.target.innerText = 'Отправляю...'
                                const res = await ReviewState.removeProgress()
                                e.target.innerText = 'Отправлено'
                                setTimeout(()=>{ window.location = window.location.origin }, 800)
                            }} className={'btn_red'}>Удалить прогресс</div>
                        </div>
                    </div>
                </>:
                <>
                    <HistoryPage setIsHistoryView={setIsHistoryView} isFinish={props.isFinish} nextTask={props.nextTask} title={props.task.answer} text={props.task.description} img={props.task.img}/>

                </>}
            {isViewAnswer?<ResultModal close={closeModal} answer={answer}/>:<></>}
        </>


    )}

export default QuestionCardDesctop