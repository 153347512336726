import React from 'react';
import s from './HistoryPage.module.css'
import {Link} from "react-router-dom";
import AsyncImage from "../../Static/AsyncImage/AsyncImage";
//title={props.task.answer} text={props.task.description} img={props.task.img}
const HistoryPage = (props) => {
    return(
        <div className={s.wrapper}>
            <div className={s.title}>{props.title}</div>
            <div className={s.text}>{props.text}</div>
            {/*<img className={s.img} src={props.img} alt=""/>*/}
            <AsyncImage stl={s.img} src={props.img} />
            <div className={s.wrapper_btn}>
                {props.isFinish?
                    <Link to={'/'}>
                        <div className={'btn_white'}>Завершить</div>
                    </Link>:
                    <div onClick={()=>{
                        props.nextTask()
                        props.setIsHistoryView(false)
                    }} className={'btn_blue'}>Следующий вопрос</div>}
            </div>
        </div>

    )
}

export default HistoryPage;