import React from 'react';
import s from './HistoryPage.module.css'
import {Link, useParams} from "react-router-dom";
//title={props.task.answer} text={props.task.description} img={props.task.img}
const HistoryPage = (props) => {
    const {id} = useParams();
    return(
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.title}>{props.title}</div>
                <div className={s.text}>{props.text}</div>
                <img className={s.img} src={props.img} alt=""/>
                <div className={s.wrapper_btn}>
                    {props.isFinish?
                        <Link to={'/promo/'+id}>
                            <div className={'btn_white'}>Завершить</div>
                        </Link>:
                        <div onClick={()=>{
                            props.nextTask()
                            props.setIsHistoryView(false)
                        }} className={'btn_blue'}>Следующий вопрос</div>}
                </div>
            </div>
        </div>

    )
}

export default HistoryPage;