import React from 'react';
import s from './QuizCard.module.css'
import {Link} from 'react-router-dom'
import {geolocated} from "react-geolocated";
import check from '../../Static/resource/check.svg'

const QuizCard = (props) => {
    return (
        <div className={s.wrapper}>

            <div className={s.title}>{props.quiz.title}</div>
            <div className={s.description}>{props.quiz.description}</div>
            {!props.isFinish?
            <Link to={'./quest/'+props.quiz.id}>
                <div className={'btn_white'}>{props.progress?'Продолжить':'Начать'}</div>
            </Link>:<>
                    <Link to={'./promo/'+props.quiz.id}>
                        <div  className={'btn_blue'}>{'Получить промокод'}</div>
                    </Link>
                </>}
        </div>
    );
};

export default QuizCard;