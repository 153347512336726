import React, {useEffect, useState} from 'react';
import userState from "../../State/UserState/UserState";
import Loader from "../../Static/Loader/Loader";
import s from './PromoCodePage.module.css'
import phone_svg from '../Static/resource/phone-svgrepo-com.svg'
import mail_svg from '../Static/resource/mail-svgrepo-com.svg'
import ReviewState from '../../State/ReviewState/ReviewState'
import StarRatings from 'react-star-ratings';
import Cookie from 'js-cookie'
import {useParams} from "react-router-dom";

const PromoCodePage = () => {
    const [text, setText] = useState('')
    const [count, setCount] = useState(0)
    const [isReview, setIsReview] = useState(false)
    const [promo, setPromo] = useState('')
    const [isLoad, setIsLoad] = useState(false)
    const {quiz} = useParams();

    const init=async ()=>{
        setIsLoad(false)

        const res = await userState.getPromoCode(quiz)
        if(res.warning || !res.code.code){
            document.location.href = document.location.origin
        }
        setPromo(res.code.code)
        setIsLoad(true)
    }

    useEffect(()=>{
        const review = Cookie.get('review')
        if(review)
            setIsReview(true)
        init()
    }
    ,[])
    const changeRating = (e)=>{
        setCount(e)
    }
    return isLoad?(
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.game}>Спасибо за игру!</div>
                <div className={s.game}>Вы получаете еще одну уникальную викторину </div>
                <div className={s.game}>при покупке игрового набора</div>
                <div className={s.propmo}>по промокоду</div>
                <div className={s.code}>{promo  }</div>
                <div className={s.description}>
                    <p>По вопросам приобретения:</p>
                </div>
                <div className={s.wrapper_phone_map_none}>
                    <a className={s.phone} href="tel:+78007753437"><img className={s.phone_svg}
                                                                      src={phone_svg} alt=""/><span>8 (800) 775-34-37</span></a>
                    <a className={s.mail} href="mailto:info@rodnyeigry.ru"><img className={s.mail_svg}
                                                                              src={mail_svg} alt=""/><span>info@rodnyeigry.ru</span></a>
                </div>
                {isReview?
                    <div>Спасибо за отзыв!</div>:
                <div>
                    <div className={s.game}>Нам очень важно ваше мнение!</div>
                    <div className={s.game}>Оцените игру</div>
                    <div className={s.stars}>
                    <StarRatings  starDimension={'30px'}
                                  starHoverColor={'#285CA6'}
                                  starRatedColor={'#285CA6'}
                                  starEmptyColor={'#93b9ff'}
                                  changeRating={changeRating}
                                  numberOfStars={5}
                                  rating={count}
                                  name='rating'/>
                    </div>
                    <textarea className={s.textarea} rows={4} value={text} onChange={e=>setText(e.target.value)} placeholder={'Здесь можно оставить впечатления от игры'} />
                    <div onClick={async (e)=>{
                        e.target.innerText = 'Отправляю...'
                        const res = await ReviewState.addReview(text, count)
                        e.target.innerText = 'Отправлено'
                        Cookie.set('review', 'true')
                        setTimeout(()=>{  setIsReview(true) }, 1200)
                    }} className={'btn_blue '+s.btn}>Оставить отзыв</div>

                </div>}
                <div onClick={async (e)=>{
                    e.target.innerText = 'Отправляю...'
                    const res = await ReviewState.removeProgress()
                    console.log(res)
                    e.target.innerText = 'Отправлено'
                    setTimeout(()=>{ window.location = window.location.origin }, 600)
                }} className={'btn_blue '+s.btn}>Удалить прогресс</div>
            </div>
        </div>
    ):(
        <Loader/>
    )
};

export default PromoCodePage;